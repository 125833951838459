<template>
    <div class="recommend-feeds">
        <StackRouterHeaderBar />
        <div class="title" v-html="'수락률 높은 회원'" />
        <div class="comment-box">
            <i class="icon material-icons-outlined"> info </i>
            <div class="comment" v-html="'호감 수락률이 상위 30% 이상인 회원입니다.'" />
        </div>
        <div class="feed-box">
            <lazy-component :is="`FeedItem`" v-for="feed in recommendFeeds" :feed="feed" :key="feed.id" />
        </div>
    </div>
</template>

<script>
import MarryState from '@/components/common/MarryState'
import FeedItem from '@/routes/feed/components/FeedItem'

export default {
    name: 'RecommendFeedPage',
    data: () => ({
        loading: false,
        lastScrollTop: 0,
        lastId: null,
        scrollLimit: 20,
    }),
    watch: {},
    methods: {
        async init() {
            try {
                this.$loading(true)
                const res = await this.$store.dispatch('loadRecommendFeeds')
                if (res === 'fail') {
                    await this.$modal.basic({
                        body: `일시적으로 이용이 불가합니다. 불편을 드려 죄송합니다.`,
                        buttons: [
                            {
                                label: 'CONFIRM',
                                class: 'btn-primary',
                            },
                        ],
                    })

                    this.$stackRouter.pop()
                }
                this.$store.dispatch('loadBadges')
            } catch (e) {
                this.$toast.error(e.data)
            } finally {
                this.$loading(false)
            }
        },
        onError(feed) {
            const left = event.target.parentNode
            const blur = left.querySelector('.blur')
            event.target.src = require(`@/assets/images/no_photo_${feed.gender}.jpg`)
            blur.classList.remove('blur')
        },
        isNewUser(feed) {
            return (
                this.$moment().diff(feed.created_at, 'hours') <= 72 && this.$moment().diff(feed.created_at, 'hours') > 0
            )
        },
        clickBanner() {
            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: 'FeedPage_Click_Topbanner',
                    option: {
                        user_id: this.$store.getters.me.id,
                    },
                },
            })
            this.$goProfileRegister()
        },
        onClickFeed(feed) {
            if (!this.$isVerifiedUser()) {
                this.$nativeBridge.postMessage({
                    action: 'sendFirebaseEvent',
                    value: {
                        category: 'Alert_ProfileRegister',
                    },
                })
                this.$modal
                    .basic({
                        body: '프로필을 자세히 보려면,<br>내 프로필을 등록하세요!',
                        buttons: [
                            {
                                label: '다음에 하기',
                                class: 'btn-default',
                            },
                            {
                                label: '등록하기',
                                class: 'btn-primary',
                            },
                        ],
                    })
                    .then(idx => {
                        if (idx === 1) {
                            this.$goProfileRegister()
                        }
                    })
            } else {
                this.$stackRouter.push({
                    name: 'UserDetailPage',
                    props: {
                        userId: feed.id,
                        from: 'RecommendFeedPage',
                    },
                })
            }
        },
    },
    components: {
        MarryState,
        FeedItem,
    },
    computed: {
        me() {
            return this.$store.getters.me || {}
        },
        feeds() {
            return this.$store.getters.feeds
        },
        page() {
            return this.$store.getters.page
        },
        recommendFeeds() {
            return this.$store.getters.recommendFeeds
        },
        profileConfirmed() {
            return (this.me.profile || {}).status !== 'created'
        },
    },
    mounted() {
        this.init()
    },
}
</script>

<style scoped lang="scss">
.recommend-feeds {
    //padding: 16px;
    .title {
        @include f-medium;
        font-size: 24px;
        color: black;
        margin-left: 16px;
    }

    .comment-box {
        margin: 16px;
        margin-bottom: 20px;
        font-size: 14px;
        padding: 12px 19px 11px;
        border-radius: 10px;
        background-color: $grey-01;
        display: flex;
        .icon {
            font-size: 16px;
            color: $grey-09;
            margin-right: 8px;
        }
        .comment {
            color: $grey-09;
            @include spoqa-f-regular;
        }
    }

    .feed-box {
        overflow-y: auto;
        height: calc(100vh - 52px - 82px - 52px);
        padding: 12px 16px 16px 16px;
        //padding: ;

        &.verified {
            height: calc(100vh - 52px - 52px);
        }

        .feed {
            // margin-top: 16px;
            margin-bottom: 12px;
            background: white;
            padding: 16px;
            border-radius: 16px;
            box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05), 0 1px 2px 0 rgba(0, 0, 0, 0.04);

            .top {
                @include between;

                .left {
                    display: flex;
                    align-items: center;
                    position: relative;

                    .blur {
                        position: absolute;
                        backdrop-filter: blur(3px);
                        -webkit-backdrop-filter: blur(3px);
                        @include round-image;
                    }

                    img {
                        @include round-image;
                    }

                    .user-info {
                        color: $grey-07;
                    }
                }
                .right {
                    position: relative;

                    .new-badge {
                        padding: 4px 8px;
                        color: white;
                        font-size: 13px;
                        background: #b1a3fc;
                        border-radius: 6px;
                        width: fit-content;
                        margin-left: 33px;
                        margin-bottom: 4px;

                        @include spoqa-f-medium;
                    }

                    .marry-state {
                        padding: 4px 8px;
                        color: white;
                        font-size: 13px;
                        background: #1cc6c6;
                        border-radius: 6px;
                        width: fit-content;
                        margin-left: 33px;

                        @include spoqa-f-medium;

                        &.marry {
                            background: #ffb300;
                        }
                    }
                }
            }
            .bottom {
                p {
                    line-height: 22px;
                    font-size: 14px;
                }
            }
        }
        .badges {
            margin-top: 24px;
            display: flex;
            flex-flow: row wrap;

            .verification-badge {
                display: flex;
                align-items: center;
                padding: 4px 10px 4px 6px;
                border: 1px solid $grey-02;
                border-radius: 14px;
                width: fit-content;
                margin-right: 6px;
                margin-bottom: 6px;
                background: $grey-01;

                i {
                    font-size: 13px;
                    margin-right: 4px;
                    color: $grey-07;
                }
                div {
                    font-size: 12px;
                    color: $grey-09;
                }
            }
        }
    }

    .view-more-btn {
        margin-top: 20px;
        margin-bottom: 12px;
        color: $grey-09;
        font-size: 15px;
        text-align: center;
        border: solid 1px $grey-03;
        border-radius: 8px;
        width: 100%;
        height: 48px;
        padding-top: 13px;
        padding-bottom: 13px;
    }
}
</style>
